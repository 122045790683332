<template>
  <section class="entity-dashboard-view">
    <el-row type="flex">
      <el-col :span="24" :xs="12" :sm="10">
        <h3 class="h2 f-weight-400">Groups</h3>
      </el-col>
      <el-col :span="24" :xs="12" :sm="14">
        <!-- <el-row :gutter="20" type="flex" justify="end" align="middle"> -->
        <!-- <el-col :span="6" :xs="10" :sm="10" v-if="checkPermissionByPermissionsName('addGroup')"> -->
        <el-button
          class="float-right w-200 mt-0"
          type="primary"
          size="medium"
          @click="onAddGroup"
        >
          <span class="el-icon-circle-plus"></span> Add Group
        </el-button>
        <!-- </el-col> -->
        <!-- </el-row> -->
      </el-col>
    </el-row>
    <div class="inner-navbar">
      <div class="d-flex align-center space-between mb-10">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">Dashboard</el-breadcrumb-item>
          <el-breadcrumb-item>Groups</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div
      class="inner-navbar-content top"
      v-if="!loading && allEntitiesGroups.length"
    >
      <el-row>
        <el-col
          :span="12"
          :xs="24"
          :sm="10"
          :lg="6"
          v-for="(entityGroup, index) of allEntitiesGroups"
          :key="index"
        >
          <el-card
            class="box-card"
            shadow="hover"
            style="margin: 5px; padding: 5px"
          >
            <div slot="header" class="clearfix">
              <span
                @click="onViewGroup(entityGroup)"
                class="on-over font-semi-bold"
                >{{ entityGroup.name }}</span
              >
              <el-dropdown trigger="click" class="float-right">
                <span class="el-dropdown-link">
                  <!-- Dropdown List<i class="el-icon-arrow-down el-icon--right"></i> -->
                  <i class="el-icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <a @click="onViewGroup(entityGroup)">
                    <el-dropdown-item icon="el-icon-view " class="yale-blue"
                      >View</el-dropdown-item
                    >
                  </a>
                  <a @click="onEditGroup(entityGroup)">
                    <el-dropdown-item
                      icon="el-icon-edit"
                      @click="onEditGroup(entityGroup)"
                      class="warning"
                      >Edit</el-dropdown-item
                    >
                  </a>
                  <a @click="onDeleteGroup(entityGroup)">
                    <el-dropdown-item
                      icon="el-icon-delete"
                      class="tomato"
                      @click="onDeleteGroup(entityGroup)"
                      >Delete</el-dropdown-item
                    >
                  </a>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div class="on-over">
              <el-row @click="onViewGroup(entityGroup)">
                <el-col :span="12">
                  <span>
                    <el-tooltip
                      effect="dark"
                      content="Entities count"
                      placement="top-start"
                    >
                      <span
                        ><img
                          src="@/assets/img/sideMenu/inactive_icons/Entities Inactive.svg"
                          alt="entity"
                          width="12"
                        />
                        <span class="count f-weight-500 pl-1">
                          {{ entityGroup.entities.length }}</span
                        >
                      </span>
                    </el-tooltip>
                  </span></el-col
                >
                <el-col :span="12">
                  <span>
                    <el-tooltip
                      effect="dark"
                      content="Form Templates count"
                      placement="top-start"
                    >
                      <span
                        ><img
                          src="@/assets/img/sideMenu/inactive_icons/Form_Inactive.svg"
                          alt="Form Template"
                          width="12"
                        />
                        <span class="count f-weight-500 pl-1">
                          {{ entityGroup.formTemplates.length }}</span
                        >
                      </span>
                    </el-tooltip>
                  </span>
                </el-col>
                <el-col :span="12">
                  <span>
                    <el-tooltip
                      effect="dark"
                      content="Doc Templates count"
                      placement="top-start"
                    >
                      <span
                        ><img
                          src="@/assets/img/icons/menu/templates_active.svg"
                          alt="entity"
                          width="12"
                        />
                        <span class="count f-weight-500 pl-1">
                          {{ entityGroup.docTemplates.length }}</span
                        >
                      </span>
                    </el-tooltip>
                  </span>
                </el-col>
                <el-col :span="12">
                  <span>
                    <el-tooltip
                      effect="dark"
                      content="Global Variables count"
                      placement="top-start"
                    >
                      <span
                        ><img
                          src="@/assets/img/sideMenu/inactive_icons/data-variable.svg"
                          alt="Global Variables"
                          width="12"
                        />
                        <span class="count f-weight-500 pl-1">
                          {{ entityGroup.globalVariables.length }}</span
                        >
                      </span>
                    </el-tooltip>
                  </span>
                </el-col>
                <el-col :span="12">
                  <span>
                    <el-tooltip
                      effect="dark"
                      content="Workflows count"
                      placement="top-start"
                    >
                      <span
                        ><img
                          src="@/assets/img/icons/menu/workflow_active.svg"
                          alt="Workflow"
                          width="12"
                        /><span class="count f-weight-500 ml-1">{{
                          entityGroup.workflows.length
                        }}</span>
                        <!-- {{ entityGroup.documents.length }} -->
                      </span>
                    </el-tooltip>
                  </span>
                </el-col>
                <el-col :span="12">
                  <span>
                    <el-tooltip
                      effect="dark"
                      content="Form Builders count"
                      placement="top-start"
                    >
                      <span
                        ><img
                          src="@/assets/img/sideMenu/inactive_icons/Entities Inactive.svg"
                          alt="entity"
                          width="12"
                        />
                        <span class="count f-weight-500 pl-1">
                          {{ entityGroup.formbuilders.length }}</span
                        >
                      </span>
                    </el-tooltip>
                  </span></el-col
                >
              </el-row>

              <!-- <span>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Documents count"
                  placement="top-start"
                >
                  <span
                    ><img
                      src="@/assets/img/icons/menu/documents_active.svg"
                      alt="entity"
                      width="12"
                    />
                    <span class="count f-weight-500 pl-1">
                      0
                    </span>
                  </span>
                </el-tooltip>
              </span> -->
            </div>
          </el-card>
        </el-col>
      </el-row>

      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="totalGroups"
      >
      </el-pagination>
    </div>
    <div class="inner-navbar-content top" v-else>
      <el-empty description="No Groups" v-if="!loading"></el-empty>
      <template v-else>
        <el-row>
          <el-col :span="6" v-for="o in 8" :key="o">
            <el-card style="margin: 5px; padding: 5px">
              <el-skeleton animated>
                <template slot="template">
                  <el-skeleton-item variant="p" style="width: 50%" />
                  <el-divider class="mt-0 mb-0"></el-divider>
                  <el-skeleton-item />
                  <div style="padding: 14px">
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        justify-items: space-between;
                      "
                    >
                      <el-skeleton-item
                        variant="text"
                        style="margin-right: 16px"
                      />
                      <el-skeleton-item variant="text" style="width: 30%" />
                    </div>
                  </div>
                </template>
              </el-skeleton>
            </el-card>
          </el-col>
        </el-row>
      </template>
    </div>
    <AddEntityGroup
      v-if="showAddEditEntityGroup"
      :existedEntityGroup="selectedEntityGroup"
      v-on:closeDialog="handleDialogClose"
      v-on:groupCreated="closeAddEditEntityGroup"
    >
    </AddEntityGroup>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import AddEntityGroup from "./AddEntityGroup";
import PermissionsHelper from "@/mixins/permissionsHelper";
import { fetchGroups, deleteGroup } from "@/repo/groupsRepo";
export default {
  components: {
    AddEntityGroup,
  },
  data() {
    return {
      entityShowSwitch: "Groups",
      loading: false,
      groupOptions: [
        {
          value: "Onboarding",
          label: "Onboarding",
        },
        {
          value: "Immigration",
          label: "Immigration",
        },
        {
          value: "Work Management",
          label: "Work Management",
        },
        {
          value: "Identity",
          label: "Identity",
        },
        {
          value: "Contracts",
          label: "Contracts",
        },
      ],
      groupSelection: "",
      dialogFormVisible: false,
      newGroupForm: {
        groupTitle: "",
        entities: "",
      },

      allEntitiesGroups: [],
      totalGroups: 0,
      showAddEditEntityGroup: false,
      selectedEntityGroup: {},
      currentPage: 1,
      pageSize: 12,
    };
  },
  mixins: [PermissionsHelper],
  computed: {
    logoUploadUrl() {
      return process.env.VUE_APP_S3_BUCKET_URL;
    },
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getActiveWorkspace",
      "getUserType",
    ]),
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    ...mapGetters("entityGroups", [
      "getAllEntityGroupsData",
      "getEntityGroupDataDeleteStatus",
      "getEntityGroupDataDeleteError",
    ]),
  },
  mounted() {
    this.fetAllEntityGroups(1);
  },
  methods: {
    routeTo() {
      if (this.entityShowSwitch == "Entities") {
        // this.$router.push("/company-entities/");
      }
    },
    handleDialogClose() {
      this.showAddEditEntityGroup = false;
    },

    handleCurrentChange(page) {
      this.fetAllEntityGroups(page);
    },

    async fetAllEntityGroups(page) {
      this.loading = true;
      let params = {
        page: page,
        limit: this.pageSize,
        order_by: "name",
        order_type: "asc",
      };
      const getAllEntityGroupsData = await fetchGroups(params);
      // await this.$store.dispatch("entityGroups/fetEntityGroupsData", params);
      this.allEntitiesGroups =
        getAllEntityGroupsData && getAllEntityGroupsData.data
          ? getAllEntityGroupsData.data
          : [];
      this.allEntitiesGroups.forEach((group) => {
        group.formbuilders = group.formbuilders.filter((item, index, self) => {
          return (
            index ===
            self.findIndex((t) => JSON.stringify(t) === JSON.stringify(item))
          );
        });
      });
      this.totalGroups = getAllEntityGroupsData.total;
      this.currentPage = parseInt(getAllEntityGroupsData.page);
      this.loading = false;
      // setTimeout(() => {
      //   this.loading = false;
      // }, 3000);
    },
    onAddGroup() {
      if (!this.checkPermissionByPermissionsName("addGroup")) {
        this.$notify.error({
          title: "Error",
          message: "Permission Denied For Add Groups.Please Contact Owner.",
        });
      } else {
        this.showAddEditEntityGroup = true;
        this.selectedEntityGroup = {};
      }
    },

    onViewGroup(group) {
      if (!this.checkPermissionByPermissionsName("viewGroups")) {
        this.$notify.error({
          title: "Error",
          message: "Permission Denied For View Groups.Please Contact Owner.",
        });
      } else {
        this.$router.push({
          name: "EntityGroupPreview",
          params: {
            group_id: group._id,
          },
        });
      }
    },

    onEditGroup(group) {
      if (
        group &&
        group.created_by &&
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser._id &&
        this.getAuthenticatedUser._id != group.created_by &&
        !this.checkPermissionByPermissionsName("editGroup")
      ) {
        this.$notify.error({
          title: "Error",
          message: "Permission Denied For Edit Groups.Please Contact Owner.",
        });
      } else {
        this.selectedEntityGroup = group;
        this.showAddEditEntityGroup = true;
      }
    },
    onDeleteGroup(group) {
      if (
        group &&
        group.created_by &&
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser._id &&
        this.getAuthenticatedUser._id != group.created_by &&
        !this.checkPermissionByPermissionsName("deleteGroup")
      ) {
        this.$notify.error({
          title: "Error",
          message: "Permission Denied For Delete Groups.Please Contact Owner.",
        });
      } else {
        this.$confirm(
          `Are you sure to delete the ${group.name} Group?`,
          "Warning",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        ).then(() => {
          this.deleteGroup(group);
        });
      }
    },
    async deleteGroup(group) {
      this.loading = true;
      try {
        await this.$store.dispatch("entityGroups/deleteEntityGroup", {
          id: group._id,
        });
        if (this.getEntityGroupDataDeleteStatus) {
          await deleteGroup(group._id);
          this.$notify.success({
            title: "Success",
            message: `${group.name} Group deleted successfully`,
          });
          this.fetAllEntityGroups(1);
        } else {
          throw this.getEntityGroupDataDeleteError;
        }
        setTimeout(() => {
          this.loading = false;
        }, 3000);
      } catch (err) {
        this.$notify.error({
          title: "Error",
          message:
            this.getEntityGroupDataDeleteError ||
            "Sorry! This Group is associated with Menu Management",
        });
        setTimeout(() => {
          this.loading = false;
        }, 3000);
      }
    },

    async closeAddEditEntityGroup(status) {
      this.showAddEditEntityGroup = false;
      if (status) {
        await this.fetAllEntityGroups(1);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.entity-dashboard-view {
  width: 90%;
  display: table;
  margin: 0 auto;
  margin-top: 40px;

  .add-button {
    padding: 0.45em 0.65em;
    color: rgb(81, 161, 0);

    &:hover {
      background-color: rgb(81, 161, 0);
      color: #ffffff;
      border-color: rgb(81, 161, 0);
    }
  }

  .inner-navbar-content {
    margin-top: 20px;
  }

  .entity-group {
    margin-bottom: 15px;

    .group-title {
      height: 45px;
      font-size: 1.1em;
    }

    .actions-btn {
      .each-btn {
        padding: 2px 5px;
        margin-left: 8px;
        border: 1px solid #efefef;
        border-radius: 3px;

        &:hover {
          background-color: #ffffff;
          border: 1px solid #dddddd;
        }

        i {
          font-size: 1.1em;
        }
      }
    }

    .entities-count {
      .count {
        font-size: 1.1em;
        line-height: 1;
      }
    }
  }

  .on-over:hover {
    cursor: pointer;
  }

  .font-semi-bold {
    font-weight: 400;
  }
  .el-button--primary.is-plain {
    color: var(--primary-color);
    background: var(--primary-contrast-color);
    border-color: var(--primary-color);
    &:hover {
      color: var(--primary-contrast-color);
      background: var(--primary-color);
      border-color: var(--primary-color);
    }
  }
}
</style>

<style lang="scss"></style>
